<template>
    <div class="nav">
        <router-view />
        <div class="nav_tabbar cus_tabbar">
            <router-link :to="'/cus_user'">
                <span class="iconfont icon-wd-c"></span>
                <span class="nav_tabbar_title">个人中心</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    beforeMount () {
        const { third_id } = this.$route.query
        if (third_id) localStorage.setItem('thirdId', third_id)
    }
}
</script>

<style lang="scss" scoped>
$fontColor: #B3B3B3;
$activeFontColor: #F2970C;

.nav {
    .nav_tabbar {
        position: fixed;
        bottom: 0;
        padding: 12px 0;
        height: 98px;
        width: 100%;
        color: $fontColor;
        background: #ffffff;
        box-shadow: 0px -1px 0px 0px rgba(126, 126, 126, 0.21);

        &.cus_tabbar {
            >a {
                width: 100%;
            }
        }

        >a {
            display: inline-block;
            width: 100%;
            text-align: center;
            text-decoration:none;

            &.router-link-exact-active {
                color: $activeFontColor;
            }

            .iconfont {
                font-size: 40px;
                display: block;
            }
        }

        &_title {
            font-size: 20px;
        }
    }
}
</style>
